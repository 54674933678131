<template>
    <div class="con-wrap">
        <JobRotationEditPopup :idx="pop.idx" v-if="pop.isShowView" @close="pop.hideView"></JobRotationEditPopup>
        <!-- 신청자 리스트 팝업 -->
        <JobRotationApplicantListPopup :idx="pop.idx" v-if="pop.isShowApplList" @close="pop.hideApplicant"></JobRotationApplicantListPopup>
        <!-- 신청서 팝업 -->
        <JobRotationApplicantViewPopup :idx="pop.idx" v-if="pop.isShowApplView" @close="pop.hideApplicant"></JobRotationApplicantViewPopup>
        <!--  -->
        <JobRotationApplicantAddPopup :idx="pop.idx" v-if="pop.isShow" @close="pop.isShow=false"></JobRotationApplicantAddPopup>
        <!-- Job Rotation 신청 intro 팝업 -->
        <!-- <JobRotationPopup :idx="pop.idx" v-if="pop.isShow" @close="pop.isShow=false"></JobRotationPopup> -->

        <CarrotTitle title="Job Rotation 관리">
            - 제목 클릭 시 등록된 Job Rotation 내용을 수정할 수 있습니다.<br>
            - 신청자 클릭 시 신청자 리스트를 확인할 수 있습니다.
        </CarrotTitle>
        <div class="board">
            <div v-if="bbs.isAuth=='Y'" class="mt-40">
                <div>

                    <div class="mb-20">
                        <select v-model="bbs.state" @click="bbs.doInit" class="w-200px">
                            <option value="">상태 전체</option>
                            <option value="P">진행 중</option>
                            <option value="D">종료</option>
                        </select>
                        <button class="btn-default float-right" @click="pop.showView(0)">Job Rotation 등록</button>
                        <div class="clear"></div>
                    </div>
                    
                    <table class="table-col">
                        <colgroup>
                            <col width="80">
                            <col width="100">
                            <col width="*">
                            <col width="180">
                            <col width="120">
                            <col width="180">
                            <col width="120">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>상태</th>
                                <th>제목</th>
                                <th>신청기간</th>
                                <th>신청자</th>
                                <th>등록자</th>
                                <th>등록일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.state=='P'?'진행 중':'종료' }}</td>
                                <td>
                                    <span v-if="irow.is_owner=='N'">{{ irow.title }}</span>
                                    <span v-else class="btn-view" @click="pop.showView(irow.idx)">{{ irow.title }}</span>
                                </td>
                                <td>{{ irow.sdate }}-{{ irow.edate }}</td>
                                <td><span class="btn-view" @click="pop.showApplicant(irow.idx)">{{ irow.applicant }}</span></td>
                                <td>{{ irow.ename }}({{ irow.kname }})</td>
                                <td>{{ irow.wdate }}</td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="7">내역이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import JobRotationEditPopup from '@/components/popup/myPIMS/JobRotationEditPopup.vue'
import JobRotationApplicantListPopup from '@/components/popup/myPIMS/JobRotationApplicantListPopup.vue'
import JobRotationApplicantViewPopup from '@/components/popup/myPIMS/JobRotationApplicantViewPopup.vue'
import JobRotationApplicantAddPopup from '@/components/popup/myPIMS/JobRotationApplicantAddPopup.vue'
// import JobRotationPopup from '@/components/popup/myPIMS/JobRotationPopup.vue'
import { useStore } from 'vuex';


export default {
    layout:"myPIMS",

    components: {
        JobRotationEditPopup, 
        JobRotationApplicantListPopup, 
        JobRotationApplicantViewPopup, 
        JobRotationApplicantAddPopup, 
        // JobRotationPopup
    },
    setup() {
        const router = new useRouter();
        const toast  = useToast();
        const store  = useStore();

        const pop = reactive({
            idx : 0,
            // Popup 노출 여부
            isShowView : false,
            isShowApplList : false,
            isShowApplView : false,

            showView : (idx) => {
                pop.idx = idx;
                pop.isShowView = true;
            },

            hideView : () => {
                pop.idx = 0;
                pop.isShowView = false;

                bbs.doSearch();
            },

            showApplicant : (idx) => {
                pop.idx = idx;
                pop.isShowApplList = true;
            },

            hideApplicant : () => {
                pop.idx = 0;
                pop.isShowApplList = false;

                bbs.doSearch();
            }
        });

        const bbs = reactive({
            isAuth : "N",

            page : 1,
            rows : 20,
            list : [], total : 0,

            state : '',

            doInit : () => {
                bbs.page = 1;
                bbs.doSearch();
            },

            doSearch : () => {
                let params = {
                    page   : bbs.page,
                    rows   : bbs.rows,
                    state  : bbs.state
                };
                axios.get('/rest/mypims/getJobRotationList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;

                        bbs.isAuth = res.data.is_auth;
                        // 현재는 팀 구성이 되어있지 않으므로 스탭을 지정하여 체크하도록 변경, 3995:슈리, 19054:헤이즐
                        if( bbs.isAuth == "N" && store.state.pd_auth == false  ) {
                        //if( bbs.isAuth == "N" ){
                            Swal.fire({
                                title : 'Job Rotation 관리',
                                text  : '권한이 없습니다.'
                            }).then(() => {
                                router.go(-1);
                            });
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            if( store.state.pd_auth == false) {
                router.go(-1);
                Swal.fire({
                    title : 'Job Rotation 관리',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            bbs.doInit();
        });

        return {pop, bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>