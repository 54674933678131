<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">Job Rotation {{ pop.idx>0?'수정':'등록' }}</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view">
                    <colgroup>
                        <col width="100">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>노출기간</th>
                            <td>
                                <carrot-date-picker v-model="pop.info.sdate" class="w-110px float-left"></carrot-date-picker>
                                <span class="mt-5 ml-5 mr-5 h-20px float-left">~</span>
                                <carrot-date-picker v-model="pop.info.edate" class="w-110px float-left"></carrot-date-picker>
                                <!-- <input v-model="pop.info.sdate" type="text" class="w-80px"> ~
                                <input v-model="pop.info.edate" type="text" class="w-80px"> -->
                            </td>
                        </tr>
                        <tr>
                            <th>제목</th>
                            <td>
                                <input v-model="pop.info.title" type="text" class="w-100per h-30px" placeholder="제목을 입력하세요.">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="layer-btn mt-10 w-100per dp-table">
                <button class="btn-default float-left">취소</button>
                <template v-if="pop.idx>0">
                    <button class="btn-default float-left ml-10" @click="pop.doDelete">삭제</button>
                    <button class="btn-default float-right" @click="pop.doModify">수정</button>
                </template>
                <button v-else class="btn-default float-right" @click="pop.doSubmit">등록</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2';
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import { useStore } from 'vuex';


export default {
    name: 'JobRotationEditPopup',
    components: {
        CarrotDatePicker
    },
    props: {
        idx: {
            type: [Number, String],
            default: 0,
        }
    },
    setup(props, {emit}) {
        const toast = useToast()
        const store  = useStore();

        const pop = reactive({
            idx  : 0,
            info : {
                sdate : "",
                edate : "",
                title : "",
                state : ""
            },

            doDelete : () => {
                let params = {
                    idx   : pop.idx
                };

                if( confirm("삭제하시겠습니까?") == true ){
                    axios.post('/rest/mypims/delJobRotation', params).then((res) => {
                        if( res.data.err == 0 ){
                            toast.info("삭제되었습니다.");
                            pop.onClose();
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                        }
                    });
                }
            },

            doModify : () => {
                let params = {
                    idx   : pop.idx,
                    sdate : pop.info.sdate,
                    edate : pop.info.edate,
                    title : pop.info.title,
                    state : pop.info.state
                };

                if( !params.sdate || !params.edate || !params.title ){
                    alert("모든 항목을 작성해주세요.");
                    return;
                }

                axios.post('/rest/mypims/modJobRotation', params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.info("수정되었습니다.");
                        pop.onClose();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSubmit : () => {
                let params = {
                    sdate : pop.info.sdate,
                    edate : pop.info.edate,
                    title : pop.info.title
                };
                console.log(params);

                if( !params.sdate || !params.edate || !params.title ){
                    alert("모든 항목을 작성해주세요.");
                    return;
                }

                axios.post('/rest/mypims/addJobRotation', params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.info("등록되었습니다.");
                        pop.onClose();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                let params = {
                    idx : pop.idx,
                };

                axios.get('/rest/mypims/getJobRotationInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info = res.data;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            if( store.state.pd_auth != true ) {
                pop.onClose();
                Swal.fire({
                    title : 'Job Rotation 관리',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            pop.idx    = props.idx?props.idx:"0";

            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>