<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-800px">
            <div>
                <p class="title">Job Rotation 신청서</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-list">
                    <colgroup>
                        <col width="80">
                        <col width="*">
                        <col width="150">
                        <col width="150">
                        <col width="150">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>시행 년도</th>
                            <th>소속</th>
                            <th>이름</th>
                            <th>직급/직급</th>
                            <th>입사일자</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ pop.info.tyear }}</td>
                            <td>{{ pop.info.office_name }} / {{ pop.info.team_name }}</td>
                            <td>{{ pop.info.ename }}({{ pop.info.kname }})</td>
                            <td>{{ pop.info.position_name }}</td>
                            <td>{{ pop.info.joinday }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="block-title mt-40">직무 로테이션 (조건: 입사 1년 이상)</div>
                <div class="block-tip">- 현 직무 외 내가 도전해 보고 싶은 직무가 있다면 희망하는 직무와 본인의 강점을 작성해주세요.</div>
                <table class="tbl-light-view mt-10">
                    <colgroup>
                        <col width="120">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <template v-for="(irow, i) in pop.rotation_1" :key="i">
                            <tr>
                                <th>{{ (i+1) }}지망</th>
                                <td>{{ irow.title }}</td>
                            </tr>
                            <tr>
                                <th>나의 강점</th>
                                <td class="preline">{{ irow.memo }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>

                <div class="block-title mt-40">조직 로테이션 (조건: 입사 1년 이상)</div>
                <div class="block-tip">- 현 조직 외 함께 일하고 싶은 조직이 있다면 조직명과 희망사유를 작성해주세요.</div>
                <table class="tbl-light-view mt-10">
                    <colgroup>
                        <col width="120">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <template v-for="(irow, i) in pop.rotation_2" :key="i">
                            <tr>
                                <th>{{ (i+1) }}지망</th>
                                <td>{{ irow.office_name + (irow.team_name?' - '+irow.team_name:'') }}</td>
                            </tr>
                            <tr>
                                <th>나의 강점</th>
                                <td class="preline">{{ irow.memo }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <div class="color-1 mt-10">※ 작성하는 모든 내용은 보안 유지되며 다음 해 인사발령 시 반영됩니다.</div>

            </div>
            <div class="layer-btn mt-10 txt-center">
                <button class="btn-default dp-inblock" @click="pop.onClose">닫기</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

export default {
    name: 'JobRotationApplicantViewPopup',
    props: {
        idx: {
            type: [Number, String],
            default: 0,
        }
    },
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            idx  : 0,
            info : {},
            rotation_1 : [],
            rotation_2 : [],

            doSearch : () => {
                let params = {
                    idx : pop.idx,
                };

                axios.get('/rest/mypims/getJRApplicantInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info = res.data;

                        pop.rotation_1 = res.data.rotation_1;
                        pop.rotation_2 = res.data.rotation_2;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx    = props.idx?props.idx:"0";

            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>